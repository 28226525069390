<script setup lang="ts">
const colorMode = useColorMode();
const isDark = computed({
  get() {
    return colorMode.value === "dark";
  },
  set() {
    colorMode.preference = colorMode.value === "dark" ? "light" : "dark";
  },
});
</script>
<template>
  <ClientOnly>
    <UButton
      :icon="isDark ? 'i-heroicons-moon-20-solid' : 'i-heroicons-sun-20-solid'"
      color="white"
      variant="ghost"
      aria-label="Theme"
      class="text-white hover:text-black"
      @click="isDark = !isDark"
    />
    <template #fallback>
      <div class="w-8 h-8"></div>
    </template>
  </ClientOnly>
</template>
