<template>
  <header
    class="flex justify-between w-full h-[--header-height] items-center fixed top-0 border-b bg-black border-black dark:border-zinc-800 dark:bg-zinc-900/75 backdrop-blur z-50"
  >
    <div
      class="w-full mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl flex justify-between"
    >
      <div class="flex items-center gap-2">
        <UIcon
          name="i-game-icons-scroll-quill"
          class="h-10 w-10 text-primary"
        />
        <div class="font-bold text-white">CodeQuest RPG</div>
        <Version />
      </div>
      <div></div>
      <div class="flex items-center gap-2">
        <ColorMode />
        <ClientOnly>
          <UButton
            label="Sign Up"
            @click="login"
            variant="solid"
            icon="i-fa6-solid-right-to-bracket"
            class="text-black"
          />
          <template #fallback>
            <USkeleton class="w-8 h-8" />
            <USkeleton class="w-24 h-8" />
          </template>
        </ClientOnly>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";

const auth0 = useAuth0();

const login = () => {
  auth0.loginWithRedirect({
    appState: {
      target: "/dashboard",
    },
  });
};
</script>
