<template>
  <div class="flex flex-col items-center">
    <div
      class="dark:from-zinc-900 dark:to-zinc-800 dark:bg-gradient-to-b dark:from-25% dark:to-100% w-full"
    >
      <LandingTopbar />
      <div
        class="w-full px-2 mt-[var(--header-height)] min-h-[calc(100vh-var(--header-height))]"
      >
        <slot></slot>
      </div>
    </div>
    <LandingFooter />
  </div>
</template>
