<template>
  <footer class="w-full flex flex-col">
    <div
      class="border-t border-black dark:border-zinc-950 dark:bg-zinc-800 py-8"
    >
      <div
        class="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 text-center md:text-start md:items-start gap-8"
      >
        <div class="flex items-center gap-2 justify-center">
          <UIcon
            name="i-game-icons-scroll-quill"
            class="h-10 w-10 text-primary"
          />
          <div class="font-bold whitespace-nowrap">CodeQuest RPG</div>
        </div>
        <div class="flex flex-col gap-4 text-center">
          <div class="font-bold text-lg">Product</div>
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Home</ULink
          >
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Features</ULink
          >
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Pricing</ULink
          >
        </div>
        <div class="flex flex-col gap-4 text-center">
          <div class="font-bold text-lg">Company</div>
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >About</ULink
          >
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Blog</ULink
          >
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Contact</ULink
          >
        </div>
        <div class="flex flex-col gap-4 text-center">
          <div class="font-bold text-lg">Legal</div>
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Terms</ULink
          >
          <ULink
            to="/"
            active-class="text-primary"
            inactive-class="text-black dark:text-white hover:text-primary"
            >Privacy</ULink
          >
        </div>
      </div>
    </div>
    <div
      class="h-20 border-t border-black dark:border-zinc-950 dark:bg-zinc-800 flex items-center justify-between px-1 sm:px-8 font-thin text-sm"
    >
      <div class="flex justify-between items-center w-full container mx-auto">
        <div class="flex">
          <span class="hidden sm:flex">Copyright </span>© 2024 CodeQuest RPG
        </div>
        <div class="flex">
          <UButton icon="i-fa6-brands-discord" variant="ghost" color="white" />
          <UButton icon="i-fa6-brands-github" variant="ghost" color="white" />
        </div>
      </div>
    </div>
  </footer>
</template>
